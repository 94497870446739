import { combineReducers } from 'redux';


import storyReducer from './reducers/story.reducer';



export default combineReducers({
  stories:storyReducer,
});
