import { SET_FETCHED_STORIES, SET_FETCHED_STORY, SET_STORY_TYPE, SET_STORY_FILTER, LOADING_MORE_STORIES } from '../actions/story.actions';

const currentState = {
  stories:[],
  story:{},
  pet_type:'all',
  filters:[],
  filter:'',
  loading:false,
}

const storyReducer = (state=currentState, action) => {
  // const { type, payload } from action;
  switch (action.type) {
    case SET_FETCHED_STORIES:
      return{
        ...state,
        stories:state.stories.concat(action.payload)
      }
    case SET_FETCHED_STORY:
      return{
        ...state,
        story:action.payload
      }
    case SET_STORY_TYPE:
      return{
        ...state,
        pet_type:action.payload
      }
    case SET_STORY_FILTER:
      return{
        ...state,
        filter:action.payload
      }
    case LOADING_MORE_STORIES:
      return{
        ...state,
        loading:action.payload
      }
    default:
      return state;
  }
}

export default storyReducer;
