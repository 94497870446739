import React from 'react';


const ForRescues = () => {

  return(
    <div className="container">
      <h1 className="mt-5 mb-3">For Rescues and Shelters</h1>
      <article>
             <p>The <strong>isavedapet.com</strong> is a free companion web site to the <a rel="noreferrer" href="http://petpalmanager.com" target="_blank">PetPal Rescue Manager</a> pet / rescue management application. The <strong>isavedapet.com</strong> service allows you to give your adopters a free place to show their newest adopted family member. Think of it as the ultimate collection of happy tails!</p>
             <p>Everyone loves to show off their new pet and isavedapet.com allows them to share their pet on a number of their social media web sites including Facebook, Twitter, Tumblr, and Reddit.</p>
             <p>This web site is free to all members to post to each time you finalize an adoption. You can pick the image of the pet, edit the story, and send the adopter a short email including the link to their new pet. All in about 2 minutes.</p>
      </article>
    </div>
  )
}

export default ForRescues;
