import React from 'react';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { setStoryType } from '../../redux/actions/story.actions';
import { useHistory  } from 'react-router-dom';


const Nav = props => {

  let history = useHistory();
  let nav = [];
  const pet_type = useSelector(state => state.stories.pet_type);
  const stories = useSelector(state => state.stories.stories);

  stories.forEach( story => {
    if(!nav.includes(story.category)){
         nav.push(story.category);
    }
  });

  const filter_pets = pet_type => {
    props.setStoryType(pet_type);
    history.push('/');
  }

  return(
    <nav className="navbar navbar-expand-lg">
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"><i className="fas fa-hamburger"></i></span>
    </button>

  <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">

    <ul className="navbar-nav">
      <li className="nav-item">
        <div className={`nav-link ${ (pet_type==="" || pet_type==='all') ? 'active' : ''}`} onClick={() => filter_pets('all')}>All</div>
      </li>
      {
        nav.map( (nav,i) => (
          <li className="nav-item" key={i}>
            <div className={`nav-link ${ pet_type==={nav} ? 'active' : ''}`} onClick={() => filter_pets(nav)}>{nav}s</div>
          </li>
        ))
      }

      <li className="nav-item d-lg-none d-block">
        <Link className="nav-link" to="for-rescues">For Rescues and Shelters</Link>
      </li>
      <li className="nav-item d-lg-none d-block">
        <Link className="nav-link" to="/contact-us">Contact Us</Link>
      </li>
    </ul>
  </div>
</nav>
  )
}


const mapDispatchToProps = dispatch => ({
  setStoryType: pet_type => dispatch(setStoryType(pet_type))
})

export default connect(null,mapDispatchToProps)(Nav);
