import React from 'react';
import {
  FacebookShareButton,
  RedditShareButton,
  TwitterShareButton,
  TumblrShareButton,
  TwitterIcon,
  FacebookIcon,
  TumblrIcon,
  RedditIcon,
} from "react-share";

const PetProfileHeader = ({pet}) => {



  return(
    <div className="pet-profile-header">
      <div className="d-flex flex-row justify-content-between">
        <div className="title-container container">
          <div className="pet-details-container">
            <div className="pet-photo-container" style={{backgroundImage:`url(${pet.photo})`}}></div>
            <div className="pet-details">
                <h1>{pet.pet_name}</h1>
                <div className="info">
                {pet.breeds }
                </div>
                <div className="info">
                  {pet.category} | {pet.gender } | { pet.age }
                </div>
            </div>


          </div>

        </div>
        <div className="share-container mt-4 mr-2 mr-lg-4 text-center d-flex">
          <div className="d-flex flex-column">
            <TwitterShareButton url={`https://isavedapet.com/story/${pet.pet_slug}`} title={`Read the adoption story of ${pet.pet_name}`} hashtags ={['#isavedapet','#petadoption','#animalrescue']}>
                <TwitterIcon size={32} round={true} className="mb-2"/>
            </TwitterShareButton>
            <FacebookShareButton url={`https://isavedapet.com/story/${pet.pet_slug}`} quote={`Read the adoption story of ${pet.pet_name}`} >
              <FacebookIcon size={32} round  className="mb-2"/>
            </FacebookShareButton>
            <TumblrShareButton url={`https://isavedapet.com/story/${pet.pet_slug}`} title={`Read the adoption story of ${pet.pet_name}`} >
            <TumblrIcon size={32} round  className="mb-2"/>
          </TumblrShareButton>
          <RedditShareButton   url={`https://isavedapet.com/story/${pet.pet_slug}`} title={`Read the adoption story of ${pet.pet_name}`} windowWidth={660}   windowHeight={460} >
            <RedditIcon size={32} round />
          </RedditShareButton>
          </div>

        </div>
      </div>

    </div>
  )
}

export default PetProfileHeader;
