import React, { Fragment, useEffect } from 'react';
import { PetCard } from '../../components';
import { fetchStories } from '../../api/story';
import { useSelector, connect } from 'react-redux';
import { setFetchedStories, loadingStories } from '../../redux/actions/story.actions';
import { Helmet } from 'react-helmet';

const Home = props => {

  // const [ pet_type, setPetType ] = useState('all')

  const stories = useSelector(state => state.stories.stories);
  const pettype = useSelector(state => state.stories.pet_type);
  const filterText = useSelector(state => state.stories.filter);
  const loading = useSelector(state => state.stories.loading);


  useEffect(() => {
    fetchStories(props.setFetchedStories,0,props.loadingStories);
    return () => setFetchedStories({})
  },[props]);

  const filterStories = stories.filter( (story,i) => {
    return (pettype==='all' || story.category.toLowerCase()===pettype.toLowerCase()) &&
            (story.pet_name.toLowerCase().includes(filterText.toLowerCase()))
  })

  const fetchMoreStories = () => {
    fetchStories(props.setFetchedStories,stories.length,props.loadingStories);
    props.loadingStories(true);
  }

  return(

    <Fragment>
      <Helmet>
        <title>Happy Tails | I Saved a Pet</title>
      </Helmet>
      <div className="container-fluid mt-5">
        <div className="card-columns">
          {
            filterStories.map( (story,i) => (
                <PetCard key={i} story={story} />
          ))

        }
        </div>
        <div className="text-center m-5 loader-container">
          {
            stories.length && !loading ?

              <button className="btn btn-primary-color" onClick={() => fetchMoreStories()}>Load More Stories</button>

            :
            <div>
              <div className="spinner-border text-info" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <h2 className="mt-3 mb-5 text-purple">Loading The Feeeeels</h2>
            </div>


          }
        </div>
      </div>
    </Fragment>




  )
}

const mapDispatchToProps = dispatch => ({
  setFetchedStories: stories => dispatch(setFetchedStories(stories)),
  loadingStories: status => dispatch(loadingStories(status)),
})

export default connect(null,mapDispatchToProps)(Home);
