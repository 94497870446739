export const SET_FETCHED_STORIES = 'SET_FETCHED_STORIES';
export const SET_FETCHED_STORY = 'SET_FETCHED_STORY';
export const SET_STORY_TYPE = 'SET_STORY_TYPE';
export const SET_STORY_FILTER = 'SET_STORY_FILTER';
export const LOADING_MORE_STORIES = 'LOADING_MORE_STORIES';

export const setFetchedStories = stories => ({
  
  type:SET_FETCHED_STORIES,
  payload:stories
});

export const setFetchedStory = story => ({
  type:SET_FETCHED_STORY,
  payload:story
});

export const setStoryType = pet_type => ({
  type:SET_STORY_TYPE,
  payload:pet_type
});

export const setFilter = filter => ({
  type:SET_STORY_FILTER,
  payload:filter
});

export const loadingStories = status => ({
  type:LOADING_MORE_STORIES,
  payload:status
});
