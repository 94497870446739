import { createStore } from 'redux';
// import logger from 'redux-logger'; //middleware
import rootReducer from './root-reducer';
import { loadState, saveState } from './localsession';
// import { loadState, saveState } from './localstorage';

const persistedState = loadState();

//array of middlewares for app
// const middlewares = [logger];

const store = createStore(rootReducer, persistedState,
window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

store.subscribe(() => {
  saveState(store.getState());
})

export default store;
