import React from 'react';
import { Nav } from '../';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/logo/logo.png';
import { setFilter } from '../../redux/actions/story.actions';
import { connect, useSelector } from 'react-redux';


const Header = props => {

  const stories = useSelector(state => state.stories.stories);

  return(
    <header>
    <div className="site-header d-flex flex-row justify-content-between">
    <div className="left-container d-flex flex-row">
    <div className="logo-container">
      <Link to="/">
        <img src={logo} className="logo img-fluid" alt="I saved a pet"/>
      </Link>
    </div>
    <div className="site-title d-flex align-items-center flex-column">
      <h1>I Saved a Pet</h1>
      <p>{stories.length ? stories[0].total.toLocaleString() : ''} Happy Tails about Adoptions</p>
    </div>
    </div>

      <div className="right-container">
      <div className="search-container pt-3">
        <form className="form-inline my-2 my-lg-0 d-lg-block d-none">
          <input className="form-control mr-sm-2" type="search" placeholder="Search By Pet Name" aria-label="Search" onChange={(e) => props.setFilter(e.target.value)} />
          {/* <button className="btn btn-primary-color my-2 my-sm-0" type="submit" aria-label="Search"><i className="fas fa-search"></i></button> */}
        </form>
      </div>
      <div className="sub-links  d-lg-block d-none">
        <Link to="/for-rescues">For Rescues and Shelters</Link>&nbsp;|&nbsp;
        <Link to="/contact-us">Contact Us</Link>
      </div>
      </div>

    </div>
    <Nav />
    </header>

  )
}

const mapDispatchToProps = dispatch => ({
  setFilter: filterText => dispatch(setFilter(filterText))
})

export default connect(null,mapDispatchToProps)(Header);
