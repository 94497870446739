import React, { useState } from 'react';
import Mailgun from 'mailgun-js';

const Contact = () => {

  const [ name, setName ] = useState('');
  const [ email_address, setEmail ] = useState('');
  const [ subject, setSubject ] = useState('Question');
  const [ comments, setComments ] = useState('');
  const [ role, setRole ] = useState('Visitor');
  const [ submitted, setSubmit ] = useState(false);

  const _submitForm = e => {
    e.preventDefault();
    // 6864a01b884aca4fa8ac128253bf7b3b-e5da0167-9615c342
    //
    let mailgun = new Mailgun({apiKey:'key-30f70570a75ad93cd58b3492d7f10904', domain:'petpalmanager.com'})
    let data = {
      from: email_address,
      to:'wkolcz@petpalmanager.com',
      subject:`isavedapet: ${subject}`,
      html:`<p>${name} submitted a ${subject} from isavedapet.com as a ${role}</p><p>${comments}</p>`
    }
    mailgun.messages().send(data, (err,body) => {
      if(err){
        console.error(err);
      }else{
        console.log(body)
        setName('');
        setEmail('');
        setSubject('Question')
        setRole('Visitor')
        setComments('');
        setSubmit(true);
      }
    })

  }

  return(
    <div className="container">
    <h1 className="mt-5 mb-3">Contact Us</h1>
    {
      submitted &&
      <div className="text-center">
        <h4 className="mt-5 mb-5"><i>You message has been submitted<br />If a reply is requested, someone will be in touch</i></h4>
      </div>
    }

      <div className="card">
      <form onSubmit={(e) => _submitForm(e)}>
        <div className="card-body">
          <p>We love hearing from you. Have any questions or comments? Please use the form below to contact us and someone will be in touch with you shortly if needed.</p>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" id="name"/>
          </div>
          <div className="form-group">
             <label htmlFor="role">You Are</label>
             <select className="form-control" id="role"  value={role} onChange={(e) => setRole(e.target.value)}>
               <option value="Visitor">Visitor</option>
               <option value="Rescue">Rescue</option>
               <option value="Adopter">Adopter</option>
               <option value="other">Other</option>
             </select>
           </div>
            <div className="form-group">
              <label htmlFor="email_address">Email address</label>
              <input type="email" value={email_address} onChange={(e) => setEmail(e.target.value)} className="form-control" id="email_address"/>

            </div>
            <div className="form-group">
               <label htmlFor="subject">Subject</label>
               <select className="form-control" id="subject"  value={subject} onChange={(e) => setSubject(e.target.value)}>
                 <option value="Question">Question</option>
                 <option value="Comment">Comment</option>
                 <option value="Complaint">Complaint</option>
                 <option value="Website Issue">Website Issue</option>
                 <option value="Take Down Request">Take Down Request (Please include pet name and rescue)</option>
               </select>
             </div>

             <div className="form-group">
                <label htmlFor="comments">Questions or Comments</label>
                <textarea className="form-control" id="comments" rows="3" onChange={(e) => setComments(e.target.value)} value={comments}></textarea>
              </div>
        </div>
        <div className="card-footer">



          <button type="submit btn btn-primary" className="btn btn-primary">Submit</button>

        </div>
        </form>
      </div>




    </div>
  )
}

export default Contact;
