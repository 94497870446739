import React, { Fragment } from 'react';
import { Header } from '../../components';

const Layout = ({children}) => {

  return(
    <Fragment>
      <Header />
      <main id="main">
          { children }
      </main>
    </Fragment>

  )
}

export default Layout;
