import React, { Fragment } from 'react';
import { PetProfileHeader } from '../../components';
import { withRouter, useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import logo from '../../assets/img/logo/logo.png';
import { setFetchedStory } from '../../redux/actions/story.actions';
import { connect, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

const Story = props => {

  const stories = useSelector(state => state.stories.stories);

  let { pet_slug } = useParams();

  const story = stories.filter( story => {
    return story.pet_slug === pet_slug
  })

  return(
    <Fragment>
      <Helmet>
        <title>Adoption Story for {story[0].pet_name}</title>
      </Helmet>
      <PetProfileHeader pet={story[0]}  />
      <div className="container mt-lg-5 mt-3">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-8 pet-story-container">
              <h2 className="mb-4">Adoption Story for {story[0].pet_name}</h2>
              <article className="pet-story">
                { ReactHtmlParser (story[0].story.replace('www.','http://www.')) }
              </article>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card rescue-details mb-5">
              <div className="card-body d-flex flex-row">
                <div className="logo-container"><img src={logo} className="logo" alt="I saved a pet" /></div>
                <div>
                  <h2 className="adopted-by">Adopted By:</h2>
                  <h3 className="mb-0 mt-2">{story[0].org_name}</h3>
                  <div>Located in {story[0].org_city}, {story[0].org_state}</div>
                  <div></div>
                </div>

              </div>
              <div className="card-footer p-0">
                <a className="btn btn-primary-color" href={`tel:${story[0].org_phone}`}>Call Us</a>
                <a className="btn btn-primary-color" href={`mailto:${story[0].org_email}?subject=Pet Adoption`}>Email Us</a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Fragment>

  )
}

const mapDispatchToProps = dispatch => ({
  setFetchedStory: story => dispatch(setFetchedStory(story)),
})

export default connect(null, mapDispatchToProps)(withRouter(Story));
