import axios from 'axios';
import {api} from './api';


export const fetchStories = (cb,start=0,lm) => {

  axios.get(`${api}/isap/stories/${start}`)
  .then( res => {
      cb(res.data);
      lm(false);
    }
  )
  .catch(e => {
    console.error(e.message);
  })
}

export const fetchStory = (pet_slug,cb) => {
  axios.get(`https://api.petpalmanager.com/api/v1/isap/story/${pet_slug}`)
  .then( res => {
    cb(res.data);
  })
  .catch( e => {
    console.error(e.message);
  })
}
