import { Router, Switch, Route } from 'react-router-dom';
import { Layout, Home, Story, ForRescues, Contact } from './pages';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

const trackingId = 'UA-81198923-2';

const tagManagerArgs = {
  gtmId: 'G-LCF64224ML'
}

TagManager.initialize(tagManagerArgs);


const history = createBrowserHistory();

ReactGA.initialize(trackingId);

function App() {

  window.dataLayer.push({
    event: 'pageview'
  });

  history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
    // window.dataLayer.push({
    //   event: 'pageview',
    //   page: {
    //     url: location.pathname,
    //     title: title
    //   }
    // });

  });

  return (
    <Router history={history}>
      <Switch>
        <Layout>
          <Route path="/" exact render={() => <Home />}/>
          <Route path="/stories/:pet_type" exact render={() => <Home />}/>
          <Route path="/story/:pet_slug" render={() => <Story />}/>
          <Route path="/for-rescues" render={() => <ForRescues />}/>
          <Route path="/contact-us" render={() => <Contact />}/>
        </Layout>
      </Switch>
    </Router>
  );
}

export default App;
