import React from 'react';
import { Link } from 'react-router-dom';

const PetCard = ({story}) => {

  return(

  <div className="card p-relative">

  <Link to={`/story/${story.pet_slug}`}><img src={story.photo} className="card-img-top" alt={story.pet_name}/></Link>
  <div className="card-body">
    <div className="adoption-date-container">
      <div className="adoption-date">
        <div className="month">{story.adoption_month}</div>
        <div className="day">{story.adoption_day}</div>
        <div className="year">{story.adoption_year}</div>
      </div>

    </div>
    <h5 className="card-title"><Link to={`/story/${story.pet_slug}`}>{story.pet_name}</Link></h5>
    <p className="card-text">{story.pet_name} was adopted from {story.org_name} by {story.first_name} from {story.city}, {story.state}</p>
  </div>
</div>
  )
}

export default PetCard;
